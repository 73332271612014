<template>
  <div class="iotDeviceDetail">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <div class="item" v-for="(item, index) in form.dataList" :key="index">
        <el-form-item
          label="序列号"
          :rules="[
            { required: true, message: '请输入序列号', trigger: 'blur' },
          ]"
        >
          <v-input
            disabled
            placeholder="请输入序列号"
            v-model="item.deviceSN"
            :width="250"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="上报时间"
          :rules="[
            { required: true, message: '请输入上报时间', trigger: 'blur' },
          ]"
        >
          <v-input
            disabled
            placeholder="请输入上报时间"
            v-model="item.reportTs"
            :width="250"
          ></v-input>
        </el-form-item>
        <el-form-item
          label="参数"
          :rules="[{ required: false, message: '参数', trigger: 'blur' }]"
        >
          <!-- <v-input
            disabled
            type="textarea"
            v-model="item.data"
            :width="400"
          ></v-input> -->
          <div class="json">
            <vue-json-editor
              v-model="item.data"
              :showBtns="false"
              :mode="'code'"
              @json-change="onJsonChange"
              @json-save="onJsonSave"
            />
          </div>
        </el-form-item>
      </div>
    </form-panel>
  </div>
</template>

<script>
import moment from "moment";
import JSONEditor from "jsoneditor";
import vueJsonEditor from "vue-json-editor";
import { getQueryDataIssueUrl } from "./api.js";
import {} from "./map.js";

export default {
  name: "placeEdit",
  data() {
    return {
      jsonData: {
        a: 1,
      },
      editor: null,
      submitConfig: {
        queryUrl: getQueryDataIssueUrl,
        submitUrl: "",
      },
      dataList: [],
      form: {
        dataList: [],
      },
    };
  },
  components: {
    vueJsonEditor,
  },
  mounted() {
    const { id } = this.$route.query;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ deviceSN: id });
    }

    this.$setBreadList("查看事件");
  },
  methods: {
    onJsonChange() {},
    onJsonSave() {},
    initJsonEditer() {
      this.$nextTick(() => {
        let _this = this;
        let container = document.getElementById("jsoneditor");
        let options = {
          mode: "code",
          indentation: 2,
          search: true,
          enableTransform: false,
        };
        this.editor = new JSONEditor(container, options);
        this.editor && this.editor.set(this.form.targetExtraData);
      });
    },
    update(data) {
      //   Object.keys(this.form).forEach((key) => {
      //     this.form[key] = data[key];
      //   });
      this.form.dataList = data.records;
      this.form.dataList.forEach((ele) => {
        if (ele.reportTs) {
          ele.reportTs = moment(ele.reportTs).format("YYYY-MM-DD HH:MM:SS");
        }
        if (ele.data) {
          ele.data = JSON.parse(JSON.parse(ele.data));
        }
        let obj = { ...ele };
        ele.data = obj;
        console.log(obj);
        console.log(ele.data);
      });
      if (this.form.dataList.length == 0) {
        this.$message.error("暂无数据！！！");
      }
    },
    submitBefore() {
      return true;
    },

    submitSuccess(data) {
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.iotDeviceDetail {
  box-sizing: border-box;
  height: 100%;
  .json {
    width: 600px;
  }
}
</style>
<style scoped>
@import "~jsoneditor/dist/jsoneditor.min.css";

.search-wrapper {
  margin: 20px;
  display: flex;
}

.pull-right {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

#jsoneditor {
  margin: 20px 20px 20px 20px;
  width: calc(100% - 40px);
  height: calc(100% - 160px);
}

#jsoneditor >>> .jsoneditor-menu {
  background-color: #409eff;
  border-bottom: 1px solid #409eff;
}

#jsoneditor >>> .jsoneditor.jsoneditor-mode-text {
  border: thin solid #409eff;
}

#jsoneditor >>> .jsoneditor-poweredBy {
  display: none;
}
</style>
